import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faPeopleGroup, faSleigh } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css';
import { connect, disconnect} from "get-starknet"
import { prepareCallTxs, getMintingState, getPeriodCurrentMinted, getPeriodTotalMintable, getPrice, getState, getWhitelisted } from "../utils/multicall.js"
import { useAccount, useConnectors, useContractWrite } from "@starknet-react/core"
import BN from "bn.js"
import BigNumber from "bignumber.js"

import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

const IndexPage = () => {
  const STATE_ID = 1;
  const [price, setPrice] = React.useState('0.0066');
  const [available, setAvailable] = React.useState('###');
  const [mintingState, setMintingState] = React.useState(false);
  const [perAcc, setPerAcc] = React.useState(1);
  const [account, setAccount] = React.useState('');
  const [nftCount, setNftCount] = React.useState(1);
  const account_hook = useAccount();
  const connectors_hook = useConnectors();


  React.useEffect(() => {
    async function loadVariables() {
      try {
        
        const state = await getState(STATE_ID)

        const _price = state.price.low.toString(); // await getPrice(network, '0');
        const _status = state.is_active.toNumber(); //  await getMintingState(network);
        const _available = state.amount.low.toString(); // Only support low
        const _per_account = 0;

        const _priceEther = new BigNumber(_price).div(new BigNumber(10).pow(new BigNumber(18))).toFixed(4);
        if(_status > 0) {
          setMintingState(_status);
          setPrice(_priceEther);
          setAvailable(new BigNumber(_available).toString());
          setPerAcc(Number(_per_account) > 0 ? Number(_per_account) : 5)
          return;
        }
        return;
      } catch (ex) {
        console.error(`Error at load : ${ex.message}`)
        return;
      }
    }

      loadVariables()


  }, [getPrice, getMintingState, getPeriodCurrentMinted, getPeriodTotalMintable])

  const handleChange = (e) => {
    console.log(e.target.value)

    setNftCount(Number(e.target.value));
  }

  const connectWallet = async() => {
    connectors_hook.refresh();
    try {
      const starknet = await connect({ modalMode: "alwaysAsk", modalTheme: "dark", })
      await starknet?.enable({ starknetVersion: "v4" })

      if(starknet?.id === 'argentX') {
        if(starknet?.chainId === 'SN_MAIN') {
          const selected_connector = connectors_hook.connectors[0].options.id === 'braavos' ? connectors_hook.connectors[1] : connectors_hook.connectors[0];
          connectors_hook.connect(selected_connector);

          setAccount(account_hook.account);

        } else {
          alert("Please change network to Mainnet");
        }
      } else if(starknet?.id === 'braavos') {
        if(starknet?.provider?.provider?.chainId === '0x534e5f4d41494e') {
          const selected_connector = connectors_hook.connectors[0].options.id === 'braavos' ? connectors_hook.connectors[0] : connectors_hook.connectors[1];
          connectors_hook.connect(selected_connector);
          setAccount(account_hook.account);

        } else {
          alert("Please change network to Mainnet");
        }
      } else {
        alert('Please connect with Argent X or Braavos wallet');
        return;
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const mint = async () => {    
    try {
      if(!mintingState) {
        return;
      }



        const priceWei = new BigNumber(price).multipliedBy(new BigNumber(10).pow(new BigNumber(18))).toString();
        const calls = prepareCallTxs(nftCount, new BN(nftCount).mul(new BN(priceWei)), 1); // todo wei convert
        console.log(calls)
        await account_hook.account.execute(calls)

    } catch (ex) {
      console.error(ex)
    }
  }

  return (

  <div class="container">
    <div class="intro_bg"></div>
    <div class="page_title">
      <h1>Expectium</h1>
    </div>

      <div class="news">
      </div>
      
      <div class="intro_description" style={{color: 'white'}}>
        <p>Expectium is a decentralized “SocialFi” platform that enables Web3 users to take a position against various global titles. Built on top of Starknet.</p>
      </div>
      <div class="section helper migration">
        <a class="helper_box2" >
          <h5>
          <div>
            <FontAwesomeIcon icon={faTicket} />
            Be a part of Expectium
          </div>
          </h5>
          <p>Share NFTs are unique revenue distribution system for Expectium. NFT holders will be share holder of whole system. They will earn platform fees directly as their NFT count.</p>
          <div class="minting_box">
            <div class="minting_info"><span>Available : {available}</span><span>{mintingState ? `Price : ${price} ETH` : 'Minting not started'}</span></div>
            <div class="minting_count_box">
            <span class="mint_count_text">Count : </span><select class="mint_count" onChange={handleChange} name="count" id="count">
                        {Array.from({length: perAcc}, (_, i) => i + 1).map((e, i) => <option value={e}>{e}</option>)}
                            </select></div> 
            <div onClick={account === '' ? connectWallet : mint } class="wallet_btn">{account === '' ? "Connect Wallet" : mintingState ? "MINT!"  : "Minting Unavailable"}</div>
          </div>
        </a>
        <a class="helper_box teaser"></a>       
      </div>
      <div class="section-s newversion">
          <h2>EXPECTIUM <br />INFORMATION MARKETS</h2>
          <p>Expectium is an information markets platform that lets you trade on the world's most highly-debated topics  (e.g. politics, economics, current events and more.) . On Expectium, you trade shares like Happens or Not of these topics based on your forecasts and earn return if you are right at market resolvation.</p>
          <p>Market prices reflect what traders think are the odds of future events, turning trading activity into actionable insights that help people make better decisions. Expectium protocol provides decentralized and community driven information market to users.</p>
          <a href="https://testnet.expectium.io" class="mainnet_btn" target="_blank" rel="noreferrer">Try Alpha</a>
      </div>
      <div class="section helper">
        <a class="helper_box" href="https://docs.expectium.io/getting-started/prediction-markets" target="_blank" rel="noreferrer">
          <h5>
		  <FontAwesomeIcon icon={faTicket} />
            Share NFTs
          </h5>
          <p>Be holder of Share NFT and earn from all Expectium revenues.</p>
        </a>
        <a class="helper_box" href="https://docs.expectium.io/hey/we-are-expectium" target="_blank" rel="noreferrer">
          <h5>
		  <FontAwesomeIcon icon={faPeopleGroup} />
            Questions DAO
          </h5>
          <p>While holding Share NFTs decide and ask for a question listing to DAO.</p>
        </a> 
        <Link className="helper_box" to="https://twitter.com/expectiumio">
          <h5>
		  <FontAwesomeIcon icon={faTwitter} />
            Twitter
          </h5>
          <p>Keep up to date with the latest developments about Expectium.</p>
        </Link> 
      </div>
      <div class="section-s last">
        <h3>Our Roadmap</h3>
        <div class="roadmap_progress"></div>
        <ul class="list">
          <li>
            <h5>Q2 2023</h5>
            <p>Community Building <br></br> Testnet Product Launch<br></br>Partnerships with Ecosystem<br></br> Initial Share NFT Offering<br></br> Early Birds AirDrops</p>
          </li>
          <li>
            <h5>Q3 2023</h5>
            <p>Expectium Mainnet Launch<br></br> Revenue Distribution for Share NFT Holders<br></br> Community Growth<br></br> Expectium Question DAO</p>
          </li>
          <li>
            <h5>Q4 2023</h5>
            <p>Staking System Launch<br></br> Offline Question Events<br/><br/> Last part of NFT Shares sale</p>
          </li>
          <li>
            <h5>Q1 2024</h5>
            <p>More features to be unlocked...</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IndexPage
